<template>
    <div class="bg-custom">
        <div class="text-center text-white pt-2" v-html="$t(`${tKey}memberList`, [items.length])" />
        <b-collapse id="collapse-1" 
            class="mt-2 y-scrollable"
            v-model="visible" 
        >
            <b-list-group id="item-list-container" >
                <b-list-group-item class="d-flex justify-content-between align-items-center small p-2"
                    v-for="(item, i) in items" :key="i" 
                    :to="$i18nRoute({ name: item.idMember ? 'MemberDetails' : 'EstablishmentDetails', params: item.idMember ? { idMember: item.idMember } : { id: item.contractId }})"
                >
                    <div class="d-flex align-items-center">
                        <ahv-logo v-if="!isFromMembers && item.idMember" class="mr-2" :height="20" />
                        <span v-html="item.name" />
                    </div>
                    <div>
                        <span v-if="item.address" v-html="formattedAddress(item)" />                     
                        <font-awesome-layers v-else>
                            <font-awesome-icon icon="map-marker-alt" transform="right-3" />
                            <font-awesome-icon icon="slash" color="red" transform="shrink-2" />
                        </font-awesome-layers>                    
                    </div>
                </b-list-group-item>
            </b-list-group>
        </b-collapse>
        <b-button @click="visible = !visible" block variant="custom">
            <font-awesome-icon :icon="visible ? 'caret-up' : 'caret-down'" size="lg" />
        </b-button>
    </div>
</template>

<script>
export default {
    name: 'ItemsList',
    props: {
        tKey: { type: String, required: true },
        items: { type: Array, default: [] },
        isFromMembers: { type: Boolean, default: false },
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        formattedAddress(item) {
            return `${item.address}, ${item.city}`
        },
    },
}
</script>

<style lang="scss">
#item-list-container {
    max-height: 30vh;
    border-radius: 0;
}
</style>